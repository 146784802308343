.button-bar {
  background-color: #fff;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(192, 174, 174, 0.12);
  display: block;
  padding: 1rem;
  position: fixed;
  width: 35%;
  z-index: 10;
  border-radius: 20px;

  @media (min-width:320px) and (max-width:767px) {
    bottom:7%;
    right: 0;
    border-radius: 0px;
    background-color: #f0f0f0;
    width: 100%;
  }

  @media (min-width:768px) {
    top:128px;
    right: 6%;
  }

  @media (min-width:1024px) {
    width: 30%;
    top:128px;
    right: 11.5%;
  }

  




  .cart-summary {
    letter-spacing: 2px;
    font-family: "MontserratSemiBold";
    text-transform: uppercase;
    font-size: 12px;
    color:#949494;
  }

  .cart-subtotal {
    font-family: "MontserratLight";
    font-weight: 600;
    font-size: 16px;
    color:#111111;
  }
  .total-to-be-charged{
    font-family: "MontserratLight";
    font-size: 14px;
  }
}

.summary-position {
  @media (min-width:768px) {
    top:172px !important;
    right: 6%;
  }

  @media (min-width:1024px) {
    width: 30%;
    top:172px !important;
    right: 11.5%;
  }
}

.cart-full {
  height: calc(100vh - 140px);

  @media (min-width: 769px) {
    height: calc(100vh - 158px);
  }
}

.cart-divider {
  border-top: 1px solid #ccc;
}

.cart-item {
  padding: 15px;

  .cart-item-title {
    font-size: 14px;
    font-family: "MontserratSemiBold";
    color: #111111;
  } 
}

.cart-container {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(210, 210, 210, 0.3);
  margin-bottom: 4.5rem !important;

  @media (max-width:540px) {
    border-radius: 0;
  }
}

.checkout-button{
  padding-top: 1.1rem !important;
  padding-bottom: 1.1rem !important;
  font-size: 14px !important; 
  font-family: "MontserratLight" !important;
}
