@import "./variables.scss";

button {
  cursor: pointer;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-primary-hover:hover {
  background-color: $primary-color;
}

.bg-secondary-hover:hover {
  background-color: $secondary-color;
}

.clickable {
  cursor: pointer;
}

.draggable {
  cursor: grab;
}

.loading-fullscreen {
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
}

.one-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon-size {
  height: 35px;
  width: 35px;
  padding: 2px;

  @media (min-width: 769px) {
    height: 40px;
    width: 40px;
    padding: 4px;
  }
}

.title {
  margin: 0.5rem;
  font-size: 18pt;

  @media (max-width: 768px) {
    font-size: 14pt;
  }
}

.title,
.primary {
  color: $primary-color;
}

.secondary {
  color: $secondary-color;
}

.subtitle,
.lightgray {
  color: #777;
}

.light-green {
  color: #7cc58e;
}

.subtitle {
  margin: 0.5rem;
  font-size: 14pt;

  @media (max-width: 768px) {
    font-size: 12pt;
  }
}

.text-center,
.title,
.subtitle {
  text-align: center !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.BigLink {
  font-size: 14pt;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.message {
  color: black;
  font-family: BigCaslon, "ProximaNovaLight", sans-serif;
  font-size: 16pt;
  text-align: center;

  @media (min-width: 769px) {
    font-size: 20pt;
  }
}

.caslon {
  font-family: BigCaslon, "ProximaNovaLight", sans-serif;
}

.alignCenterFullScreen {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.elevation-light {
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.175em 0.5em,
    rgba(2, 8, 20, 0.08) 0px 0.085em 0.175em;
}

.elevation-1 {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.elevation-3 {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.elevation-5 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
}

.elevation-8 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.elevation-10 {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
    0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.elevation-md-light {
  @media (min-width: 768px) {
    box-shadow: rgba(2, 8, 20, 0.1) 0px 0.175em 0.5em,
      rgba(2, 8, 20, 0.08) 0px 0.085em 0.175em;
  }
}

.elevation-md-1 {
  @media (min-width: 768px) {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
}

.elevation-md-3 {
  @media (min-width: 768px) {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
      0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  }
}

.elevation-md-5 {
  @media (min-width: 768px) {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
  }
}

.elevation-md-8 {
  @media (min-width: 768px) {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}

.elevation-md-10 {
  @media (min-width: 768px) {
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
      0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
}

.w-100 {
  width: 100% !important;
}

.w-85 {
  width: 85% !important;
}

.w-80 {
  width: 80% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.h-100 {
  height: 100% !important;
}

.h-75 {
  height: 75% !important;
}

.h-50 {
  height: 50% !important;
}

.h-25 {
  height: 25% !important;
}

.mb-10 {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .mobile-hidden {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .mobile-only {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .notebook-only {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .notebook-hidden {
    display: none !important;
  }
}

@media (max-width: 350px) {
  .mobile-text-small {
    font-size: 10pt !important;
  }
}

.no-scalable {
  resize: none;
}