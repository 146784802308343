.FacebookButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: none;
  background-color: #4267b2;
  color: #fff;
  font-family: "Montserrat";
  font-size: 15px !important;
  font-weight: 500;
  padding: 0.5rem 0.8rem;
  transition: 0.2s ease-in;
  height: 46px;

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
