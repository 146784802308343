// This file will have definitions for all global colors

$primary-color: #658071;
$secondary-color: #ffafa5;


/*
  New Design
*/
$black               : #000000;
$secondary-black     : #111111;
$white               : #ffffff;
$primary-color-alpha : #e1e5e4;
$secondary-green     : #637f71;
$soft-green          : #658071;
$secondary-color-new : #f8aba0;
$dark-grey           : #999999;
$grey-new            : #a2a2a2;
$basic-grey          : #d8d8d8;
$light-grey          : #c9c9c9;
$grey-light-new      : #e4e4e4;
$grey-lighter-new    : #f1f1f1;
$yellow              : #fffcea;
