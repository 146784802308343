@import "../../variables.scss";

.cover-preview {
  margin-bottom: 40px;
  cursor: pointer;

  img {
    object-fit: cover;
    height: 100%;
    border: 1px solid rgba($primary-color, 0.5);
  }

  .selected-cover {
    opacity: 0.5;
  }

  .anticon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}