.ant-time-picker-panel-select ul
{
 padding: 0px;
} 

.baby-date-picker i
{
    cursor: pointer;
}

.delete-child-text {
  @media(max-width:425px) {
    font-size: 13px;
  }
  @media(min-width: 768px) {
    font-size:16px;
  }
  font-family: "Montserrat";
  color:#658071;
  text-decoration: underline;
  line-height: 21px;
}

.child-input-label {
  font-family: "MontserratLight";
  color:#909090;
  font-size: 13px;

  @media (max-width:380px) {
    font-size: 12.5px;
  }
}

.save-child-button {
  font-family: "MontserratLight" !important;
  font-size: 16px !important;
  padding: 0.6rem 0.7rem !important;
  @media (max-width:400px) {
    font-size: 14px !important;
  }
}
