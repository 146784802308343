// .milestone-card {
//   width: 80%;
//   margin: 0 auto;

//   .ant-card-body {
//     background: #f9f9f9;
//     border: 1px solid #d8d8d8;
//     border-radius: 11.5px;
//     padding: 0;
//     height: 212px;
//     width: 168px;
//     transition: 0.3s ease-in;
//   }

//   .milestone-image {
//     height: 70%;
//     background-color: #FFF;
//   }

//   .milestone-image img {
//     width: auto;
//     height: auto;
//     max-width: 90%;
//     max-height: 90%;
//   }

//   .milestone-title {
//     height: 30%;
//     background: #FFF;
//   }

//   .milestone-title p {
//     margin: 0;
//     text-align: center;
//     font-size: 10pt;
//     color: black;
//     font-weight: bold;
//   }

//   @media (max-width: 992px) {
//     .ant-card-body {
//       height: 250px;
//     }
//   }

//   @media (max-width: 425px) {
//     .ant-card-body {
//       height: 170px;
//     }
//   }
// }

.milestones-card-container {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 11.5px;
  padding: 0;
  height: 200px;
  width: 168px;

  @media (max-width: 767px) {
    width: 132px;
    height: 152px;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    width: 168px;
    height: 200px;
  }

  .completed-icon {
    margin-left: 8.5rem;
    margin-top:0.85rem;
    margin-bottom: -2rem;

    @media (max-width:767px) {
      margin-top:0.75rem;
      margin-left: 6.8rem;
      margin-bottom:-1.85rem;
    }
  }
}

.completed-overlay {
  background-color: rgba(238,238,238,0.5) !important;
}

.image-opacity {
  opacity: 0.5;
}

.milestones-max-width-column {
  max-width: 178px !important;
  margin-right: 0 !important;

  @media (max-width: 767px) {
    max-width: 140px !important;
    max-height: 172px !important;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    max-width: 170px !important;
    max-height: 220px !important;
  }
}

.milestones-image {
  width: 146px;
  height: 146px;

  @media (max-width: 767px) {
    width: 110px;
    height: 110px;
  }
}

.milestones-text-bold {
  font-family: "MontserratBold";
  color: #111111;
  font-size: 16px !important;
}

.milestones-text-semiBold {
  font-family: "MontserratSemiBold";
  color: #111111;
  font-size: 14.5px !important;

  @media (max-width: 767px) {
    font-size: 13px !important;
  }
}

.milestones-text-regular {
  font-family: "Montserrat";
  color: #aaaaaa;
  font-size: 16px !important;

  @media (max-width: 767px) {
    font-size: 13px !important;
  }
}
