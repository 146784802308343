@import "../../variables.scss";


.maintenance-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.maintenance-image {

  aspect-ratio: 800 / 533;
    @media (min-width:1024px) {
      height: 533px;
      width: 800px;
    }

    @media (min-width:1024px) and (max-height:768px) {
      height: 333px;
      width: 600px;
    }
}

.maintenance-heading {
  font-family: "MontserratSemiBold";
  font-size: 48px;

  @media (max-width:450px) {
    font-size:36px;
  }

  @media (max-width:320px) {
    font-size: 32px;
  }
}

.maintenance-text {
  font-family: "Montserrat";
  font-size: 19px;
  text-align: center;

  @media (max-width:450px) {
    font-size:15px;
  }
}

.social-icon {
  path {
    transition: fill 0.3s ease-out;
  }

}

.social-icon:hover {
  path {
    fill:$secondary-color !important; 
  }
}