@import '../../variables.scss';

.babypage-item-preview {
  margin-bottom: 40px;
  margin-top: 20px;
  width: 169px !important;
  cursor: grab;

  .babypage-image {
    height: 182px;
    min-width: 126px !important;
    @media (max-width: 500px) {
      height: 135px;
    }

    @media (min-width:1024px) and (max-height:768px) {
      height: 157px;
    }

  }
  img {
    border: 1px solid rgba($primary-color, 0.5);
    border-bottom: none;
  }

  .selected-cover {
    opacity: 0.5;
  }

  .babypage-content {
    background-color: #fff;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    min-height: 91px;
    position: absolute;
    padding: 0.5em;
    border-radius: 8px;
    box-shadow: 0 6px 10px 0 rgba(230, 230, 230, 0.5);

    @media (max-width: 500px) {
      min-height: 68px !important;
    }

    @media (max-width: 320px) {
      transform: translate(-50%, -50%);
    }

    @media (min-width:1024px) and (max-height:768px) {
      min-height: 65px;
    }

    .babypage-text {
      width: 100%;
    }

    .title {
      text-align: center;
      font-size: 11px;
      margin: 0;
      font-family: "MontserratSemiBold";
      color:#111111;
      @media (min-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 500px) {
        font-size: 12px;
      }
    }

    .subtitle {
      text-align: center;
      font-size: 10px;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: "Montserrat";
      color:#949494;
      @media (min-width: 768px) {
        font-size: 15px;
      }

      @media (max-width: 500px) {
        font-size: 11px;
      }
    }

    @media (max-width: 500px) {
      width: 70%;
    }
  }

  .anticon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.forbidden {
  cursor: not-allowed !important;
}
