.edit-text {
  label {
    font-size: 14pt;
  }

  p {
    margin-bottom: 0;
  }

  input,
  .ant-select {
    margin: 10px 0 10px;
    font-size: 12spt;
  }
}
