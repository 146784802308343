.color-selection {
  cursor: pointer;
  height: 20px;
  width: 20px;

  &:hover,
  &.selected {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
  }
}
