@import "../../variables.scss";

.book-product {
   p {
    font-family: 'Montserrat' !important;
    font-size: 13px;
    margin-bottom: 0;

   }
   .ant-input-number-input {
      text-align: center;
   }

   
   .book-option-padding {
      padding: 0;
   }

   
   .ant-input-number-handler-wrap {
      display: none;
   }
   .book-qty {
      padding: 0 5px;

      @media (max-width:320px) {
         padding: 0 5px;
      }
   
   }

   .book-item-details {
      font-size: 12px;
   }
}

.cart-book-actions-margin {
   margin-left: -0.5rem !important;
}

.cart-sub-actions-margin {
   margin-left: -1.35rem !important;
}

.cart-giftcard-actions-margin {
   margin-left: 0.1rem !important;
}

.book-option-title {
   color: #949494;
}

.book-option-price {
   color:$black
}

.giftcard-price {
   color:$black;
   font-family: 'Montserrat' !important;
   font-size: 13px;
   margin-bottom: 0;
}

.cart-book-actions {
   font-family: 'Montserrat' !important;
   font-size: 13px;
   margin-bottom: 0;
   color: $primary-color;
   cursor: pointer;
   text-decoration: underline;
}

.subscription-option-price {
   color:$black;
   font-family: 'Montserrat';
   font-size: 13px;
}

.subscription-option-title {
   color: #949494;
   font-family: 'Montserrat';
   font-size: 13px;
}

.book-item-border-left {
   border-left: 1px solid $primary-color;
}

