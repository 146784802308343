.product-thumb {
  height: 50px;
  font-size: 50px;
  width: 50px;
}

.no-history-container{
  height: 50vh;

  .no-history-text {
    font-family: "MontserratSemiBold";
    margin-bottom: 0;
  }
}


