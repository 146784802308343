@import '../../variables.scss';

body {
  height: 100%;

  input {
    border-radius: 5px !important;
  }

  button {
    box-shadow: none !important;
  }

  img {
    width: 100%;
  }

  .backgroundHome {
    background-image: url(../../assets/img/homeBackground.png);
    background-position: center;
    background-size: cover;

    .row {
      margin-bottom: 0 ;
    }
  }

  .homeBtn {
    padding: 10px 50px;
    border-radius: 2.18rem;
    color: #fff;
    background-color: #658071;
    border: none;
    width: 100%;
  }

  .homeHeight {
    min-height: 100vh;
  }

  .homeTitle {
    font-weight: bold;
    text-transform: uppercase;
  }

  // This to avoid styling-by-default-related issues on Safari Mobile
  input[type=text],
  input[type=email],
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .AuthLogo {
    transition: 0.1s ease-in;
    width: 80px;
    height: auto;
    @media (min-width: 1919px) {
      left: 21%;
    }
    @media (min-width: 2559px) {
      left: 25%;
    }
  }

  .enter-form .ant-btn-primary:not(disabled):hover,
  .new-standard-btn:hover {
    background: $secondary-green;
    border-color: $secondary-green;
  }

  .enter-form .ant-btn-primary[disabled]:hover,
  .new-standard-btn[disabled]:hover {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }

  .AuthContainer input,
  .AuthContainer .ant-select-selection {
    font-family: "ProximaNovaLight";
    font-size: 14px;
    height: 40px !important;
    border-color: $light-grey;
    color: $secondary-black !important;

    &::placeholder,
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: $dark-grey;
    }

    .ant-select-selection__rendered {
      height: 100%;

      .ant-select-selection-selected-value {
        margin-top: 4px;
      }
    }
  }

  .AuthContainer .ant-calendar-picker .ant-calendar-picker-icon {
    color: $dark-grey;
  }

  .AuthContainer .title {
    line-height: 1;
    font-family: "LibreCaslonDisplay";
    color: $secondary-black;
    font-size: 22px;
    @media (min-width: 768px) {
      font-size: 40px;
    }
    @media (min-width: 1024px) {
      text-align: left !important;
      font-size: 56px;
    }
  }
  .AuthContainer button,
  .new-standard-btn {
    height: 46px !important;
    font-size: 16px !important;
  }

  button.FacebookButton {
    max-width: 250px !important;
    display: inline;
    font-size: 14px !important;
    @media (min-width: 768px) and (max-width:1023px) {
      margin: 5rem 0 1rem 0;
    }
  }

  button.AppleButton {
    max-width: 250px !important;
    display: inline;
    font-size: 14px !important;
    @media (min-width: 768px) and (max-width:1023px) {
      margin: 5rem 0 1rem 0;
    }
  }

  .second-title {
    font-family: "LibreCaslonDisplay";
    font-size: 30px;
    line-height: 1.2;
    color: $secondary-black;
  }

  .Step {
    @media (min-width: 1919px) {
      top: 20px;
      right: 18%;
    }
    @media (min-width: 2559px) {
      right: 23%;
    }
  }

  .custom-alert-pink {
    background-color: $secondary-color-new;
    font-weight: bolder;
    font-size: 16pt;
    border-radius: 0;
    border: none;
    width: 100%;
    height: 54px;

    .ant-alert-message {
      font-family: "Montserrat";
      font-size: 16px;
    }
  }

  .modal-tutorial {
    @media(max-width: 1023px) {
      height: 100vh;
      width: 100vw;
      margin: 0;
      top: 0 !important;
      background-color: #f0f0f0;
    }
      
    
      max-width: 100vw !important;
    @media (min-width: 1280px) {
      width: 1100px !important;
    }
    @media (min-width: 1024px) {
      width: 900px !important;
    }

    @media(max-width: 1023px) {
      width: 100% !important;
      height: 100vh;
      .ant-modal-body {
        height: 80%;
       }
       .ant-modal-footer {
        height: 20%;
       }
  }

    .ant-modal-content {
      @media(max-width: 1023px) {
        height: 100vh;
        width: 100vw;
        margin: 0;
        top: 0 !important;
        background-color: #f0f0f0;
      }

      @media (min-width: 1024px) {
        margin: 0;
      }

      .ant-modal-footer{
        div {
          @media(max-width:1023px) {
            display: flex;
            align-items: center;
            justify-content: center;
             width: 100%;
              height: 100%;
          }

          button {
            width: 180px !important;

            @media (min-width:768px) and (max-width:820px) {
              width: 65% !important;
              padding: 32px 30px;
            }
          }
        }
        background-color: #fff;
      }
    }

    .ant-modal-body {
      padding-bottom: 60px;
      @media (min-width: 1024px) {
        padding-bottom: 0;
      }

      .ant-carousel {
        .slick-slider,
        .slick-initialized .slick-slide {
          height: 100%;
        }
  
        .slick-slide {
          img {
            margin-top: -8%;
            width: 100%;

            @media (min-width:768px) and (max-width:820px) {
              width: 70%;
            }
          }
          
          > div {
            width: 100% !important;
      
            .item-container {
              display: flex !important;
              font-family: "Montserrat";
              font-size: 18px;
              padding-top: 50px;
              line-height: 1.4;
              flex-direction: column;
              text-align: center;

              @media (min-width:768px) and (max-width:820px) {
                font-size: 24px;
              }
              @media screen and (min-width: 1024px) {
                flex-direction: row;
                text-align: left;
              }
              .text {
                color:#000;
                p {
                  width: 100%;

                  @media(min-width:1024px) {
                    width: 75%;
                  }
                }
              }
              .image {
                @media (max-width:520px) {
                  margin-top: 1.5rem;
                }
              }
        
              > .text,
              > .image, {
                flex: 1;
                display: grid;
                place-items: center;
              }
            }
          }
        }
  
        .slick-dots-bottom {
          @media(min-width:1024px) {
            bottom: -40px;
          }
          
        }

        .slick-dots li {
          &.slick-active button {
            background: $secondary-color-new;
            height: 10px;
            width: 10px;
          }
    
          button {
            background: $grey-new;
            height: 10px;
            width: 10px;
            border-radius: 50%;
          }
        }
      }
    }

    .ant-modal-footer {
      border-top: none;
      padding: 16px 16px;
      text-align: center;
      @media (min-width: 768px) {
        text-align: right;
      }

      button {
        border-color: $soft-green;
        color: $soft-green;
      }
    }
  }

  .AuthContainer {
    margin-top: 0px;
    @media (min-width: 1024px) {
      margin-top: 80px;
    }
    @media (min-width: 2500px) {
      max-width: 50% !important;
    }

    &.custom-style {
      @media (min-width: 768px) {
        padding-bottom: 20vh;

        .add-child-form .large-wrapper {
          position: absolute;
          margin: 25em 0 3em 0;
          min-width: 200px;
          right: -30%;
        }
      }
    }

    @media (min-width: 768px) {
      .styled-container {
        border-bottom: 1px solid $grey-light-new;
        padding-bottom: 70px;
      }
    }

    &.LogoHidden {
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }

    .AuthImgHeader {
      margin-bottom: -4vh;
      width: 28vw;
      @media (min-width: 1253px) {
        width: 18vw;
      }
      @media (min-width: 1300px) {
        width: 25vw;
      }
      @media (min-width: 1500px) {
        width: 18vw;
      }

      @media (min-width: 1100px) {
        &.tullips {
          max-width: 22vw;
        }
      }
    }

    .responsive-title,
    .responsive-sub-title {
      font-family: "MontserratLight";
      text-align: center;
      @media (min-width: 768px) {
        text-align: left;
      }
    }

    .responsive-title {
      font-family: "LibreCaslonDisplay";
    }

    .form-container {
      margin-top: 90px;
      @media (min-width: 768px) {
        margin-top: 0px;
      }

      > div {
        text-align: center;
      }
    }

    .DividerText {
      font-family: "Montserrat";
    }

    // Form
    .has-error .ant-form-explain,
    .has-error .ant-form-split {
      padding: 5px 0 !important;
    }
  }

  .SignInList {
    font-size: 13pt;

    li {
      margin: 0.5rem 0;
    }
  }

  .message-container-desktop {
    margin-top: -23px;
  }

  .pr-lg-30px {
    padding-right: 15px;
    @media (min-width: 768px) {
      padding-right: 30px;
    }
  }
  .pl-lg-20px {
    padding-left: 15px;
    @media (min-width: 768px) {
      padding-left: 24px;
    }
  }

  @media (min-width: 992px) {
    .vertical-divider {
      border-right: 1px solid $grey-light-new;

      &.vertical-divider-text:after {
        content: "OR";
        position: absolute;
        font-weight: bold;
        background: $white;
        top: 16px;
        right: -9px;
        height: 20px;
        width: 16px;
        font-size: 12px;
      }
    }
  }

  .btn-link-fix {
    padding: 0;
    text-align: center;

    @media (min-width:320px) and (max-width:991px) {
      margin-top: -2px;
    }
    
    @media (min-width: 992px) {
      text-align: left;
      margin-top: -1px;
    }
    @media (max-width: 1279px) and (min-width: 1024px) {
      font-size: 10pt !important;
    }

    @media (min-width: 1280px) {
      margin-top: -4px;
    }

    span {
      font-size: 14px;
    }
  }

  .StepMargin {
    @media (max-width: 991px) {
      margin-top: 125px;

      &.custom-style {
        margin-top: 95px;
      }
    }
  }

  .ant-form-explain,
  .ant-form-extra {
    text-align: left;
  }

  .helpIconWrapper {
    text-align: initial;
    padding-top: 10px;
  
    svg {
      cursor: pointer;
    }
  }
}

.test-bold {
  font-family: "MontserratBold";
  letter-spacing: 0.5px;
  color:#000000;
}

.test-semiBold {
  color:#000000;
  font-family: "MontserratSemiBold";
}

.test-regular {
  font-family: "Montserrat";
  // color:#000000;
}

.test-button {
  font-size: 1rem;
}

.test-margin-bottom {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.signin-button-color {
  background-color: #658071;
}

.enter-form {
  .ant-form-explain{
    padding-top: 3px !important;
  }
}
