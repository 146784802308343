.float-btn {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12) !important;

  &.top-right {
    top: 98px;
    right: 20px;

    @media (max-width: 768px) {
      top: 80px;
    }
  }

  &.top-left {
    top: 98px;
    left: 20px;

    @media (max-width: 768px) {
      top: 80px;
    }
  }

  &.bottom-right {
    bottom: 20px;
    right: 20px;
  }

  &.bottom-left {
    bottom: 20px;
    left: 20px;
  }
}
