@import '../../variables.scss';

.babypages-message {
  font-family: "Montserrat";
  font-size: 16px;
}

.ant-carousel .slick-slide {
  display: flex !important;
  justify-content: center !important;
  cursor: grab;

  .babypage-detail {
    background-color: #fafafa;
    left: 50%;
    transform: translate(-50%, -55%);
    padding: .5em;
    position: absolute;
    width: 130px;
    box-shadow: 0 6px 5px 0 rgba(230, 230, 230, 0.5);
    min-height: 48.5px;
    border-radius: 9px;
  }

  .babypage-title {
    font-size: 14px;
    margin: 0;
    text-align: center;
    font-family: 'MontserratSemiBold' !important;
  }

  .babypage-subtitle {
    font-size: 10px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    color:#949494;
    font-family: 'Montserrat';
  }

  .carousel-img {
    height: 150px;
    margin: 0 15px;
    width: 150px;
  }

  @media (min-width: 320px) and (max-width:364px) {
    height: 165px;
    .carousel-img {
      height: 120px;
      width: 126px;
      margin: 0;
    }

    .babypage-detail {
      width: 116px;
    }

    .babypage-title {
      font-size: 12px;
    }

    .babypage-subtitle {
      font-size: 11px;
    }
  }

  @media (min-width: 365px) and (max-width:767px) {
    height: 185px;
    .carousel-img {
      height: 146px;
      width: 156px;
      margin: 0;
    }

    .babypage-detail {
      width: 146px;
    }

    .babypage-title {
      font-size: 12px;
    }

    .babypage-subtitle {
      font-size: 11px;
    }
  }

  @media (min-width: 768px) and (max-width:1023px) {
    height:190px;

    .babypage-detail {
      width:130px;
    }

    .babypage-title {
      font-size: 12px;
    }

    .babypage-subtitle {
      font-size: 11px;
    }

    .carousel-img {
      height: 156px;
      width: 146px;
    }
  }

  @media (min-width: 1024px) and (max-width:1440px) {

    height:230px;
    .babypage-detail {
      width: 167px;
      border-radius: 8px;
    }

    .babypage-title {
      font-size: 13px;
    }
    .babypage-subtitle {
      font-size: 12px;
    }

    .carousel-img {
      height: 203px;
      width: 190px;
    }
  }

  @media (min-width: 1440px) {

    height:240px;
        .babypage-detail {
          width: 167px;
          border-radius: 8px;
        }
    
        .babypage-title {
          font-size: 14px;
        }
        .babypage-subtitle {
          font-size: 13px;
        }
    
        .carousel-img {
          height: 203px;
          width: 190px;
        }
      }
    

  
}

.green-button {
  background-color: #658071 !important;
  color: #fafafa !important;
}


.babypage-card-title {
    display: flex;
    color: #000 !important;
    justify-content: space-between;
    align-items: space-between;
    font-family: 'MontserratBold' !important;
    font-size: 10.6px !important;

    h2 {
      color: #000 ;
    }
    .add-icon {
      padding-bottom: 6px;
      justify-content: end;
      align-items: end;
    }
}

.card-title {
  display: flex;
  color: #000;
  justify-content: center;
  align-items: center;
  font-family: 'MontserratBold' !important;
  font-size: 10.6px !important;

  h2 {
    color: #000;
  }
}

.dashboard-button {
  font-size: 14px !important;
  font-family: 'Montserrat' !important;

  @media (min-width:320px) and (max-width:364px) {
    font-size: 11px !important;
  } 

  @media (min-width:365px) and (max-width:480px) {
    font-size: 13px !important;
  }

  @media (max-width:768px) {
    padding:20px !important
  }
}

.babypage-carousel .slick-dots {
  bottom: 1px;
}

.babypage-carousel .slick-dots li button {
  width: 7px;
  height: 7px;
  border-radius: 40px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: #323643;
  opacity: 0.3;
}

.babypage-carousel .slick-dots li.slick-active button {
  width: 7px;
  height: 7px;
  border-radius: 40px;
  border: solid 1px #000;
  opacity: 0.6;
  background-color: #fff;
}

.babypage-carousel {
 
  .slick-dots li {
    display: none
  }

  .slick-dots li.slick-active,
  .slick-dots li.slick-active + li,
  .slick-dots li.slick-active + li + li,
  .slick-dots li.slick-active + li + li + li,
  .slick-dots li.slick-active + li + li + li + li {
    display: inline-block;
  }
 
  .slick-dots li:nth-last-child(1),
  .slick-dots li:nth-last-child(2),
  .slick-dots li:nth-last-child(3),
  .slick-dots li:nth-last-child(4),
  .slick-dots li:nth-last-child(5){
    display: inline-block;
  }
  
  .slick-dots li.slick-active ~ li:nth-last-child(1),
  .slick-dots li.slick-active ~ li:nth-last-child(2),
  .slick-dots li.slick-active ~ li:nth-last-child(3),
  .slick-dots li.slick-active ~ li:nth-last-child(4),
  .slick-dots li.slick-active ~ li:nth-last-child(5)  {
    display: none;
  }
  
  .slick-dots li.slick-active + li + li + li + li:nth-last-child(5),
  .slick-dots li.slick-active + li + li + li + li:nth-last-child(4),
  .slick-dots li.slick-active + li + li + li + li:nth-last-child(3),
  .slick-dots li.slick-active + li + li + li + li:nth-last-child(2),
  .slick-dots li.slick-active + li + li + li + li:nth-last-child(1),
  .slick-dots li.slick-active + li + li + li:nth-last-child(5),
  .slick-dots li.slick-active + li + li + li:nth-last-child(4),
  .slick-dots li.slick-active + li + li + li:nth-last-child(3),
  .slick-dots li.slick-active + li + li + li:nth-last-child(2),
  .slick-dots li.slick-active + li + li + li:nth-last-child(1),
  .slick-dots li.slick-active + li + li:nth-last-child(5),
  .slick-dots li.slick-active + li + li:nth-last-child(4),
  .slick-dots li.slick-active + li + li:nth-last-child(3),
  .slick-dots li.slick-active + li + li:nth-last-child(2),
  .slick-dots li.slick-active + li + li:nth-last-child(1),
  .slick-dots li.slick-active + li:nth-last-child(5),
  .slick-dots li.slick-active + li:nth-last-child(4),
  .slick-dots li.slick-active + li:nth-last-child(3),
  .slick-dots li.slick-active + li:nth-last-child(2),
  .slick-dots li.slick-active + li:nth-last-child(1){
    display: inline-block;
  }

}
