.bottom-nav {
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 20px;
  background-color: #0b2c37;
  margin-bottom: 0px !important;
  z-index:100;

  @media (min-width:320px) and (max-width:767px) {
      height:61px;
  }

  @media (min-width:768px) and (max-width:1024px) {
    height:84px;
  }

  @media(min-width:1024px) and (max-height: 768px) {
    height:70px;
    padding: 8px;
  }

 

  .bottom-nav-text {
    color:#909090;
    font-size: 13px;
    font-family: "Montserrat";

    @media (max-width:767px) {
      font-size: 10px;
    }

    @media (max-width:374px) {
      font-size: 9px;
    }
  }

  @media (max-width: 767px) {
    padding: 6px 10px 10px 10px;
  }

  .footer-item {
    margin:0 3rem;
    

    @media (max-width:1024px) {
      margin: 0 3rem;
    }

    @media (max-width:768px) {
      margin: 0 2rem;
    }

    @media (max-width:620px) {
      margin: 0 0.5rem;
    }

    @media (max-width:375px) {
      margin: 0 0.3rem;
    }
  }
}

.Footer {
  position: absolute;
  bottom: 0;
  height: 80px;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 20px;
  margin-bottom: 0 !important;

  .FooterTexts {
    font-size: 12pt;
    a {
      font-size: 12pt;
    }

    @media (max-width: 1024px) {
      font-size: 10pt;
    }

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
}

