.babyAge {
  font-family: "MontserratLight";
  color: #000000;
  font-weight: 300;
  font-size: 50px;

  @media (max-width:1024px) {
    font-size: 40px;
  }
  margin: 0;
}

.baby-age-date {
  font-family: "Montserrat";
  margin: 0;
  color: #000000;

  @media (max-width:1024px) {
    font-size: 13px;
  }
}

.babyage-card {
  background-color: #fffaf5 !important;

  @media(max-width:767px) {
    background-color:#f0f0f0 !important
  }
}

.baby-age-birthday {
  color: #989696;
  font-family: "Montserrat";

  @media (min-width:768px) and (max-width:820px) {
    font-size: 12px;
  }
}
