.paymentInfo {
  font-size: 14px;

  @media (max-width: 450px) {
    font-size: 11px;
  }

  @media (max-width: 350px) {
    font-size: 9px;
  }
}

.purchase-details-container {
  max-width: 320px !important;
}

.gift-card-row {
  display: flex;
  flex-direction: column;
}

.grey-bottom-border {
  border-bottom: 1px solid #eee;
  padding-bottom: 0.25rem;
  margin-bottom: 0.5rem;
}

.book-preview-link {
  cursor: pointer;
  color:#637F71;
  &:hover {
    color:#FFAFA5;
  }
} 


.reminder-header {
  font-weight: bolder;
  font-size: 1rem;
}

.reminder-list {
  font-weight: bold;
  padding-inline-start: 1.5rem;
  width: 50%;
  font-size: 0.9rem;
  @media (max-width:767px) {
    width: 90%
  }

  @media (min-width:1280px) {
    width: 35%
  }

  li:not(:last-child) {
    margin-bottom: 10px; 
  }
}

.trial-text {
  color: black;
  @media(max-width:520px) {
    width: 70% !important;
  }
}

