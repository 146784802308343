@import '../../variables.scss';

.cropper-wrapper {
  display: block;
  min-height: 300px;
  padding: 8px;
  position: relative;
  
}

.image-edit-modal {
  position: absolute;
  overflow: hidden;
  .ant-modal {
    .ant-modal-content {
      .ant-modal-footer{
         div {
          .ant-btn {
            font-size: 11pt !important;
            padding: 18px 15px !important;
          }
         }
      }
    }
  }
}

.crop-modal-title {
  font-family: "MontserratBold";
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.62px;
}

.crop-modal-subtitle{
  font-size: 15px;
  color:$black;
  font-family: "MontserratLight";
  font-weight: 600;
}

.round-cropper {
  .cropper-crop-box, .cropper-view-box {
    border-radius: 50%;
}

.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}
}

.title-tips {
  margin: 0.5rem;
  font-size: 15pt !important;

  @media (max-height:714px) and (min-width:1200px) {
    font-size: 12.5pt !important;
  }

  @media (max-width: 768px) {
    font-size: 12pt !important;
  }

  @media(max-width:450px) {
    font-size: 11pt !important ;
  }
}

.baby-photo-steps {
  font-family: "MontserratLight";
  font-size: 15px;
  color:$primary-color
}

.negative {
  margin-top: -1.25rem
}

.rotate-btn {
  .anticon {
    line-height: 0.5;
  }
}