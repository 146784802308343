
body,
main.Content {
  background: #ffffff;
}

.btn {
  background: #658071;
  border-radius: 2.1875rem;
  border: 0;
  color: #fefefe;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1rem 3rem;
}

.btn:hover {
  background: #658071;
  color: #fefefe;
}

.btn:focus {
  background: #658071;
  color: #fefefe;
}

.container-gift-card .plan-btn.small {
  padding: 23px 0;
  font-family: "Montserrat";
  font-size: 16px;
  margin-bottom: 1rem;
}

.container-gift-card .coupon-btn.active,
.container-gift-card .coupon-btn.disabled {
  padding: 17px 0!important;
  font-size: 14px!important;
}


.container-gift-card .coupon-btn.disabled {
  background: #f1f1f1;
  color: #505050;
  cursor: default;
}

.container-gift-card .top-titles h2 {
  font-family: "MontserratSemiBold";
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 300;
  color: #658071;
}

.container-gift-card .top-titles h1,
.container-gift-card .wrapper-gift-card h4,
.container-gift-card .container-amount h2 {
  font-family: "BigCaslon";
  font-weight: 500;
  font-size: 34px;
  line-height: 1;
}

.container-gift-card .top-titles h1 {
  font-family: "BigCaslonMedium";
  font-size: 34px;
}

.container-gift-card .top-titles p:not(.light-green),
.container-gift-card .wrapper-gift-card p,
.container-gift-card .container-amount p,
.container-gift-card .container-select-amount p {
  font-family: "Montserrat";
  color: #505050;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
}

.container-gift-card .wrapper-gift-card p {
  font-weight: 100;
}

.container-gift-card .wrapper-gift-card img,
.container-gift-card .container-amount img {
  max-width: 100%;
}

.container-gift-card .wrapper-gift-card h4 {
  font-size: 32px;
}

.container-gift-card .container-amount,
.container-gift-card .container-amount-small {
  background-color: #f1f1f1;
}

.container-gift-card .container-amount p {
  font-size: 16px;
}

.container-gift-card .container-select-amount p {
  color: rgba(0,0,0,.85);
  font-size: 18px;
}

.container-gift-card .container-select-amount .container-floating-cards {
  padding-left: 2rem!important;
  padding-right: 2rem!important;
}

.container-gift-card .container-select-amount .container-floating-cards .floating-card {
  border: 1.5px solid #dddddd;
  border-radius: 8px;
  cursor: pointer;
}

.container-gift-card .container-select-amount .container-floating-cards .floating-card.active {
  border: 2px solid #ffafa5;
}

.container-gift-card .container-select-amount .container-floating-cards .floating-card p {
  font-family: "Montserrat";
  color: #111111;
  font-size: 14px;
  font-weight: 600;
}

.container-gift-card .container-select-amount .container-floating-cards .floating-card ul li {
  list-style: none;
}

.container-gift-card .container-select-amount .container-floating-cards .floating-card ul li p {
  color: #505050;
  font-size: 14px;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.6;
}

/* Custom Select */
.custom-select-child__control {
  border-radius: 0!important;
  padding: 1rem .3rem .6rem !important;
  position: relative !important;
}

.custom-select-child__control:before {
  position: absolute;
  content: "Amount";
  top: 7px;
  left: 0;
  width: 100%;
  height: 20px;
  font-size: 10px;
  color: #111111;
  text-align: left;
  padding-left: 15px;
  font-weight: bolder;
}

.plan-btn {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  padding: 1.5rem 15px;
  line-height: 0;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
  font-weight: 400;
  outline: 0;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  width: 200px;
}

span.custom-select-child__indicator-separator {
  display: none !important;
}

@media (max-width: 1024px) {
  .container-gift-card .top-titles h2 {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .container-gift-card .top-titles h1 {
    font-size: 40px;
  }

  .container-gift-card .top-titles p {
    font-size: 17px !important;
  }
  
  .container-gift-card .wrapper-gift-card img {
    border-radius: 8px;
  }

  .container-gift-card .container-amount {
    height: auto;
    max-height: 425px;
  }

  .container-gift-card .container-select-amount {
    margin-top: 150px;
  }

  .container-gift-card .container-select-amount .container-floating-cards:first-child {
    padding-left: 3rem!important;
    padding-right: 0!important;
  }
  
  .container-gift-card .container-select-amount .container-floating-cards:nth-child(2) {
    padding-left: 1.5rem!important;
    padding-right: 1.5rem!important;
  }
  
  .container-gift-card .container-select-amount .container-floating-cards:last-child {
    padding-left: 0!important;
    padding-right: 3rem!important;
  }
}

@media screen and (min-width: 1024px) {
  .container-gift-card .container-amount {
    height: auto;
    max-height: 530px;
  }

  .container-gift-card .container-select-amount .container-floating-cards:first-child {
    padding-left: 6rem!important;
    padding-right: 0!important;
  }
  
  .container-gift-card .container-select-amount .container-floating-cards:nth-child(2) {
    padding-left: 3rem!important;
    padding-right: 3rem!important;
  }
  
  .container-gift-card .container-select-amount .container-floating-cards:last-child {
    padding-left: 0!important;
    padding-right: 6rem!important;
  }
}

@media screen and (min-width: 1200px) {
  .container-gift-card .top-titles h2 {
    font-size: 16px;
  }

  .container-gift-card .container-select-amount {
    margin-top: 190px;
  }

  .container-gift-card .wrapper-gift-card p {
    font-size: 16px;
  }
}

@media screen and (min-width: 1300px) {
  .container-gift-card .top-titles h2 {
    font-size: 20px;
  }

  .container-gift-card .top-titles h1 {
    font-size: 48px;
  }

  .container-gift-card .top-titles p {
    font-size: 21px !important;
  }

  .container-gift-card .container-amount {
    max-height: 555px;
  }

  .container-gift-card .container-amount h2 {
    font-size: 38px;
  }

  .container-gift-card .container-amount p {
    font-size: 21px;
  }

  .container-gift-card .container-select-amount p {
    font-size: 21px;
  }

  .container-gift-card .wrapper-gift-card p,
  .container-gift-card .container-select-amount .container-floating-cards .floating-card p {
    font-size: 18px;
  }

  .container-gift-card .container-select-amount .container-floating-cards .floating-card ul li p {
    font-size: 16px;
  }
}
