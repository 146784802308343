@import '../../variables.scss';

.baby-carousel {
  .row {
    margin-bottom: 0 !important;
  }
}

.mobile-profile-modal .ant-modal-content {
    @media(max-width: 767px) {
      height: 100vh;
      width: 100vw;
      margin: 0;
      top: 0 !important;
      background-color: #f0f0f0;
    }
  }

  .mobile-profile-modal {

    @media (max-height:714px) and (min-width:1200px) {
      top:0 !important;
    }
    
    @media(max-width: 767px) {
      height: 100vh;
      width: 100vw;
      margin: 0;
      top: 0 !important;
      background-color: #f0f0f0;
    }
  }
  
  .mobile-profile-modal{
    @media(max-width: 767px) {
      .ant-modal-body {
        height: calc(100vh - 110px);
       }
    }
  }
  
  .baby-circle-container {
    .baby-circle {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    
    .baby-circle-border {
      border: 2px solid $primary-color;
    }

    .baby-overlay {
        opacity: 0.7;
    }
  
  
    
    .baby-circle-title {
      font-size: 13px;
      font-family: "MontserratSemibold";
      color: #000000;
    }
  
  }
  
  .baby-profile-photo {
    width: 380px;
    height: 380px;

    @media (max-height:714px) and (min-width:1200px) {
      width: 330px;
      height: 330px;
    }
    
  
    @media (max-width:500px) {
      width: 272px;
      height: 272px;
    };
  }
  .ant-carousel {
    .profile-photo-carousel {
     .slick-slide {
        height:100px !important;
      }
  
      
       .slick-dots li button {
        width: 7px;
        height: 7px;
        border-radius: 40px;
        background-color: #323643;
        opacity: 0.3;
      }
      
       .slick-dots li.slick-active button {
        width: 7px;
        height: 7px;
        border-radius: 40px;
        border: solid 1px #000;
        opacity: 0.6;
        background-color: #fff;
      }
      
     
       
        .slick-dots li {
          display: none
        }
      
        .slick-dots li.slick-active,
        .slick-dots li.slick-active + li,
        .slick-dots li.slick-active + li + li {
          display: inline-block;
        }
       
        .slick-dots li:nth-last-child(1),
        .slick-dots li:nth-last-child(2),
        .slick-dots li:nth-last-child(3) {
          display: inline-block;
        }
        
        .slick-dots li.slick-active ~ li:nth-last-child(1),
        .slick-dots li.slick-active ~ li:nth-last-child(2),
        .slick-dots li.slick-active ~ li:nth-last-child(3) {
          display: none;
        }
        .slick-dots li.slick-active + li + li:nth-last-child(3),
        .slick-dots li.slick-active + li + li:nth-last-child(2),
        .slick-dots li.slick-active + li + li:nth-last-child(1),
        .slick-dots li.slick-active + li:nth-last-child(3),
        .slick-dots li.slick-active + li:nth-last-child(2),
        .slick-dots li.slick-active + li:nth-last-child(1){
          display: inline-block;
        }
      
      }
     
  }
  