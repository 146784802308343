@import '../../../variables.scss';

.tutorial-carousel{
    .ant-carousel .slick-prev {
        display: block !important;
        color: $primary-color !important;
        font-size: 1.75rem;
        margin: 0 10rem 0 0;

        &::before {
            content: '';
          }

          &:hover {
            color: $secondary-color !important
          }

          @media (min-width: 1024px) {
            font-size: 1.75rem;
          }

          @media (max-width: 768px) {
            font-size: 1.5rem;
          }

          @media (max-width: 414px) {
            font-size: 1.25rem;
          }

          @media (max-width: 280px) {
            font-size: 0.90rem;
          }
    }

    .ant-carousel .slick-next {
        display: block !important;
        color: $primary-color !important;
        font-size: 1.75rem;
        margin: 0 0 0 10rem;

        &::before {
            content: '';
          }

          &:hover {
            color: $secondary-color !important
          }

          @media (min-width: 1024px) {
            font-size: 1.75rem;
          }

          @media (max-width: 768px) {
            font-size: 1.5rem;
          }

          @media (max-width: 414px) {
            font-size: 1.25rem;
          }

          @media (max-width: 280px) {
            font-size: 0.90rem;
          }
    }
}