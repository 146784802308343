.img-thumb {
  height: 160px;
  width: 160px;

  @media (min-width: 360px) {
    height: 180px;
    width: 180px;
  }

  @media (min-width: 400px) {
    height: 200px;
    width: 200px;
  }

  @media (min-width: 450px) {
    height: 220px;
    width: 220px;
  }

  @media (min-width: 520px) {
    height: 250px;
    width: 250px;
  }
}