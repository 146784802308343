.child-menu {
    font-size: 16px;
    font-family: "Montserrat" !important;
    color:#111111;
}

.child-menu span{
    margin-left:-6px
}

.dropdown-arrow {
    position: absolute;
}