.click-through {
    pointer-events: none;
}

// @media (max-width: 414px) {
//     .click-through p {
//         font-size: 50%;
//     }
// }

// @media (max-width: 375px) {
//     .click-through p {
//         font-size: 45%;
//     }
// }