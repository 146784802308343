// This file will have global styles and also overwrites in Ant.Design and other modules
@import 'bootstrap/scss/bootstrap-grid.scss';
@import './fonts.scss';
@import './variables.scss';
@import './antDesignOverwrite.scss';
@import './utils.scss';

html,
body {
  margin: 0;
  font-family: "ProximaNovaLight", 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
  width: 100% !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  font-size: 12pt;
  &:hover {
    color: $secondary-color;
  }
}
