@import '../../variables.scss';

    
    .custom-alert-container {
      background-color: $secondary-color;
      z-index: 1;
      width: 100%;
      height: 54px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
    }
    
    .custom-alert {
      background: var(--secondary-color);
     
    }
    
    .custom-alert p {
      color:rgb(0,0,0);
      font-family: "MontserratBold";
      font-weight: bold;
      font-size: 13px;
      margin: 14px 5px !important;
      line-height: normal;
      padding: 0 0;
      letter-spacing: normal !important;
    }

    .custom-alert-container p .styled-link:hover {
      color:#637f71;
    }

    .custom-alert-container p .styled-link {
      font-size: 13px;
    }

    
    @media (max-width:820px) and (min-width: 700px) {
      .custom-alert p {
        font-size: 12.5px;
      }
      
    }
  
  
    @media (max-width:414px) {
        .custom-alert-container p {
          font-size: 12px;
        }
        .custom-alert-container p .styled-link {
          font-size: 12px;
        }
    }
    @media (max-width:375px) {
      .custom-alert-container p {
        font-size: 11px !important;
      }
      .custom-alert-container p .styled-link {
        font-size: 11px !important;
      }
    }
  
    @media (max-width:320px) {
      .custom-alert-container p {
        font-size: 9px !important;
        margin: 14px 5px !important;
      }

      .custom-alert-container p .styled-link {
        font-size: 9px !important;
      }
  }
