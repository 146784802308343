.modal-image {
  width: 50vw;

  @media (min-width: 769px) {
    width: 350px;
  }

  &.zoomed {
    @media (min-width: 769px) {
      width: 35vw;
    }
  }
}

.actions {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .ant-btn-background-ghost {
    background-color: #ffffff !important;
  }
}
