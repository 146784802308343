.guest-user-table{
    td {
       padding: 12px !important;
    }
 }
 
 .mobile-guest-user-list {
   .ant-list-item {
     padding: 10px !important;
   }
   }