.my-orders .alexanders-order-img {
  max-height: 90%;
  max-width: 90%;
  position: absolute;
  align-items: normal !important;
}

.my-orders .alexander-order-list-item {
  font-size: 10px;
  cursor: pointer;
}

@media (max-width: 450px) {
  .my-orders .alexander-order-list-item > div {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 375px) {
  .my-orders .alexander-order-list-item {
    font-size: 12px;
  }
}

@media (min-width: 414px) {
  .my-orders .alexander-order-list-item {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .my-orders .alexander-order-list-item {
    font-size: 10px;
  }
}

@media (min-width: 1024px) {
  .my-orders .alexander-order-list-item {
    font-size: 12px;
  }
}