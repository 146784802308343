@import "../../../variables.scss";

.plus-alert {
  position: absolute;
  background-color: $white;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 8px;
  color: $secondary-color-new;
  border: 1px solid;
  border-radius: 8px;
  border-color: $secondary-color-new;
  z-index: 1;
}
