@font-face {
  font-family: "BigCaslon";
  src: local("BigCaslon"), url(./assets/fonts/BigCaslon.otf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype"),
    url(./assets/fonts/Roboto/Roboto-Medium.ttf) format("truetype"),
    url(./assets/fonts/Roboto/Roboto-MediumItalic.ttf) format("truetype"),
    url(./assets/fonts/Roboto/Roboto-Italic.ttf) format("truetype"),
    url(./assets/fonts/Roboto/Roboto-Light.ttf) format("truetype"),
    url(./assets/fonts/Roboto/Roboto-LightItalic.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "EBGaramond";
  src: local("EBGaramond"),
    url(./assets/fonts/EBGaramond-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "JosefinSans";
  src: local("JosefinSans"),
    url(./assets/fonts/JosefinSans-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratLight";
  src: local("MontserratLight"),
    url(./assets/fonts/Montserrat-Light.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url(./assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url(./assets/fonts/Montserrat-Bold.ttf) format("truetype");
    font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans"),
    url(./assets/fonts/NunitoSans-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "LibreCaslonDisplay";
  src: local("LibreCaslonDisplay"),
    url(./assets/fonts/LibreCaslonDisplay-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "ProximaNovaLight";
  src: local("ProximaNovaLight"),
    url(./assets/fonts/ProximaNovaLight.ttf) format("truetype");
  font-display: swap;
}
