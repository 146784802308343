.stork-img {
    width: 30%
}

.text {
    text-align: center;
}

.close-link {
    cursor: pointer;
}

.add-info-container {
    .ant-form-item {
        margin-bottom: 8px !important;
    }
}