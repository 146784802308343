.marginT {
  margin-top: 1rem;
}


@media (min-width: 520px) {
  .lineWrap {
    white-space: pre-line;
  }

  .changedisplay {
    display: flex;
  }

  .marginT {
    margin-top: 0;
  }
}


