@import '../../variables.scss';



.PlansBox {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
  padding: 3em;
  margin-bottom: 3em;

  li {
    color: $primary-color;
    font-size: 14pt;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 991px) {
    box-shadow: none;
    padding: 0.5em;
    li {
      font-size: 12pt;
    }
  }
}

.FreeText {
  font-size: 12pt;
}

.BigLink,
.FreeText {
  @media (max-width: 991px) {
    text-align: center;
  }
}

.plan-bold {
  font-family: "MontserratSemiBold" !important;
}

.plan-light {
  font-family: "MontserratLight" !important;
}
.no-border {
  .select-plan-button {
    height: 36px !important;
    background-color: $primary-color !important;
    color: $white ;
    font-family: "Montserrat";
    font-size: 15px;
  }
}

.plan {
  .select-plan-button {
    height: 36px !important;
    background-color: $primary-color !important;
    color: $white;
    font-family: "Montserrat";
    font-size: 15px;
  }
}


.SavingText {
  font-size: 13pt;
  font-weight: bold;
}

.TinyTextOnMobile {
  @media (max-width: 768px) {
    font-size: 10pt !important;
  }

  @media (max-width: 320px) {
    font-size: 9pt !important;
  }
}

.FreeButton {
  @media (max-width: 768px) {
    font-size: 12pt !important;
  }

  @media (max-width: 320px) {
    font-size: 10pt !important;
  }
}

/*
  New Design
*/

.store-wrapper {

  .plans-parent {
    background: $white;
    z-index: 10;
    @media screen and (min-width: 768px) {
      margin-top: 180px;
    }

    .plans-container {
      cursor: pointer;
      
      .plan {
        font-family: "Montserrat";

        &.plan-free{
          border: 2px solid #ffffff;
        }

        &:hover,
        &.active {
          border: 2px solid $secondary-color-new;
        }

        p {
          position: relative;
          font-size: 14px;
          margin-bottom: 0;
          font-family: "MontserratSemiBold";

          strong {
            span {
              text-transform: uppercase;
              color: $secondary-color-new;
              letter-spacing: 2px;
            }
          }
        }

        .detail {
          color: $grey-new;
          font-size: 12px;
          text-transform: capitalize;
        }

        &.plan-year {
          background-color: #ffe0dc;
          height:8rem;
          @media screen and (min-width: 768px) {
            height: 7rem;
          }

          > p:last-child {
            height: 92%;

            > span:last-child {
              position: absolute;
              bottom: 0;
            }
          }
        }

        &.plans-quarterly {
          background-color: #c2e2db;
          height:8rem;
        }

        &.plans-monthly{
          background-color: #dff1f7;
          height:8rem;
        }
        
        .ant-radio-checked .ant-radio-inner {
          border-color: $secondary-color-new;
          background-color: $secondary-color-new;
        }

        .ant-radio-inner {
          background-color: $white;

          &:after {
            border-radius: 100%;
            top: 4.2px;
            left: 4.2px;
            height: 6px;
            width: 6px;
            background-color: $black;
          }
        }
      }
    }
  }

  .styled-container {
    position: relative;
    
    .btn-continue {
      position: fixed;
      width: 250px;
      z-index: 999;
      left: 50%;
      bottom: 24px;
      transform: translate(-50%, -50%);

      @media (max-width:520px) {
        bottom: 50px;
      }

      &:hover {
        background-color: $secondary-green;
        border-color: $secondary-green;
      }
    }
  }

  ul.perks {
    font-family: "Montserrat";
    list-style: none;
    border-bottom: 2px solid $grey-lighter-new;
    padding-bottom: 40px;
    margin-bottom: 30px;

    li {
      font-family: "MontserratLight";
      margin-bottom: 5px;
      color: $secondary-black;

      &:before {
        content: '✓';
        margin-right: 5px;
        font-weight: 700;
      }
    }

    &:last-child {
      border-bottom: none;

      li {
        &:before {
          display: none;
        }
      }
    }
  }

  .giftcard-wrapper {
    border-bottom: 1px solid $grey-lighter-new;

    ul {
      cursor: pointer;
    }
  }

  .ant-carousel {
    @media screen and (min-width: 768px) {
      width: 200%;
      position: relative;

      .slick-initialized .slick-slide {
        display: flex !important;
        justify-content: left !important;
      }
    }

    .slick-slide {
      text-align: center;
      height: auto !important;

      h3 {
        font-family: "Montserrat";
        color: #000;
        text-transform: uppercase;
        line-height: 3;
        font-weight: bold;
        margin-bottom: 0;
        font-size: 12px;
        letter-spacing: 2px;
        @media screen and (min-width: 768px) {
          font-size: 14px;
        }

        + p {
          font-family: "LibreCaslonDisplay";
          line-height: 1;
          font-size: 22px;
          @media screen and (min-width: 768px) {
            font-size: 31px;
          }
        }
      }

      > div > div {
        @media screen and (min-width: 768px) {
          position: relative;
          width: 175% !important;
          max-width: 547.8px;
        }

        img {
          display: inline !important;
          margin-top: 20px;
          width: 26vw;
          @media screen and (min-width: 768px) {
            margin-top: 40px;
            margin-bottom: 40px;
          }
          @media screen and (min-width: 1815px) {
            width: 17vw;
          }
        }

        @media screen and (min-width: 768px) {
          .text-wrapper {
            position: absolute;
            top: 0;
            width: 80%;
            text-align: left;
            right: -83%;
            @media screen and (min-width: 800px) and (max-width: 992px) {
              right: -78%;
            }
            @media screen and (min-width: 1200px) {
              right: -84%;
            }
          }
        }
      }
    }
    
    .slick-dots-bottom {
      bottom: 0px;
      @media screen and (min-width: 768px) {
        display: flex !important;
        padding-left: 20%;
      }
    }

    .slick-dots li {
      &.slick-active button {
        background: $secondary-color-new;
        height: 10px !important;
        width: 10px !important;
      }

      button {
        background: $grey-new;
        height: 10px !important;
        width: 10px !important;
        border-radius: 50%;
      }
    }
  }

  .footer {
    font-family: "Montserrat";
  }

  .top-titles h2 {
    color: #658071 !important;
    font-family: "MontserratSemiBold";
    font-size: 17.5px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 5px;
    margin-top: 2rem;
  }
  
  .top-titles h1 {
    font-family: "LibreCaslonDisplay";
    font-size: 37px;
    line-height: 1.3;
  }
  
  .top-titles p {
    
    font-family: "Montserrat";
    font-size: 20px;
  }

  .plans-links {
    font-family: "Montserrat";
    font-size: 12px;
    color: $secondary-black;

    a {
      text-decoration: underline;
      font-size: 12px;
    }
  }

  .plans-parent-desktop {
    .plan p,
    .plan a {
      font-family: "Montserrat";
      font-size: 16px;
    }

    .plan p {
      color: $secondary-black;
    }

    .right-border {
      border-right: 1.5px solid $grey-lighter-new;
    }
    .plan-anual{
      background-color:#ffe0dc;
    }

    .plan-quarterly{
      background-color: #c2e2db;
    }
    
    .plan-monthly{
      background-color:#dff1f7;
    }
    
    .plan {
      border: 1.5px solid $grey-lighter-new;
      border-top: none;

      &:last-child {
        border-right: none;
      }

      &.no-border {
        border-left: none;
      }

      &.right-border {
        border: none;
        border-bottom: 1.5px solid $grey-lighter-new;
        position: relative;
    
        &:before {
          border: none;
          border-right: 1.5px solid $grey-lighter-new;
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          height: 45%;
        }
      }

      p strong span {
        text-transform: uppercase;
        color: $secondary-color;
        letter-spacing: 2px;
      }
    }

    p {
      display: block;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
  }
  
  .inline-row-ele,
  .plans-parent-desktop .plan p strong {
    display: flex !important;
    justify-content: space-between !important;
  }

  .little-banner {
    background: $grey-lighter-new;

    &.custom-little-banner {
      margin: 0 15px;
      width: 100%;
    }

    p {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .plan-btn {
    font-size: 15px;
    padding: 1.5rem 15px;
    line-height: 0;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
      0 1px 14px 0 rgba(0, 0, 0, 0.12) !important;
    font-weight: 400;
    outline: 0;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    width: 200px;
    background: $primary-color;
    border-radius: 2.1875rem;
    border: 0;
    color: #fefefe;
    cursor: pointer;

    &.small {
      padding: 1.15rem 10px;
      max-width: 100%;
      font-size: 14px !important;
      height: auto !important;
    }
  }

  .BooksBox {
    position: relative;
    background-color: $grey-lighter-new;
    margin-bottom: 00px;
    padding: 3rem 0 10rem;
    @media screen and (min-width: 1024px) {
      padding: 8rem 0 10rem;
    }

    h1 {
      text-align: left;
    }

    .lg-img-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-items: center;
      align-items: center;
      position: absolute;
      top: 0;

      img {
        left: 0;
        width: 50%;
        bottom: 0;
        max-height: 100%;
        max-width: 600px;

        &.xl-img {
          max-width: 700px;
          margin-left: 3%;
        }
      }
    }

    img {
      margin-left: -15px;
    }

    .img-container img {
      width: 145%;
      margin-top: 3rem;
    }

    button {
      box-shadow: none !important;
      height: auto !important;
    }
  }
  
  .BooksBox h1,
  .GiftCardBox h1 {
    font-family: "LibreCaslonDisplay";
    color: $black;
    font-size: 31px;
    _font-weight: 700;
    line-height: 1.3;
  }

  .GiftCardBox img{
    max-width: 100%;
  }
  
  .BooksBox p,
  .GiftCardBox p {
    font-family: "MontserratLight";
    font-size: 14px;
    color: $secondary-black;
  }
  
  .GiftCardBox {
    margin-top: -180px;
    
    @media screen and (min-width: 1024px) {
      h1 {
        font-size: 48px;
      }
    }

    button {
      box-shadow: none !important;
      height: auto !important;
    }
  }
  
  .BooksBox .plan-btn,
  .GiftCardBox .plan-btn {
    background: $primary-color;
    color: #fefefe;
    border: 1.4px solid $primary-color;
    box-shadow: none;
    padding: 1.1rem 15px;
  }

  .GiftCardBox {
    ul li {
      list-style: none;
      position: relative;
      margin-bottom: 0.5rem;
      width: 40%;
      float: left;

      &:before {
        content: url("../../assets/img/drawn-star-1-black.svg");
        height: 10px;
        width: 10px;
        position: absolute;
        top: 0;
        left: -20px;
      }

      &.filled-star-icon:before {
        content: url("../../assets/img/drawn-star-3-black.svg");
      }
    }

    .img-container img {
      width: 40%;
      @media screen and (min-width: 1024px) {
        width: 14%;
      }
    }
  }
}

.bottom-select-plan {
  div {
    height: 71px !important;
    .select-plan-button {
      height: 36px !important;
      background-color: $primary-color !important;
      color: $white ;
    }
  }

  .select-yearly {
    background-color: #ffe0dc;
  }

  .select-quarterly {
    background-color: #c2e2db;
  }

  .select-monthly {
    background-color: #dff1f7;
  }
 
}

.select-plan-mobile {
  text-decoration: underline;
  font-family: "MontserratSemiBold";
  font-size: 14px !important;
  color:$primary-color;
}

.margin-bottom-navigation-medium {
  @media (min-width:320px) {
    margin-bottom: 71px;
  }
  
  @media (min-width:767px) {
    margin-bottom: 91px;
  }
  
  @media (min-width:1024px) {
    margin-bottom: 124px;
  }

  @media (min-width:1024px) and (max-height:768px) {
    margin-bottom: 104px;
  }
}