@import '../../variables.scss';

.share-title {
  font-size: 18pt;
  text-align: center;
  margin-bottom: 20px;
}

.share-buttons .ant-btn {
  cursor: pointer;
  font-size: 12pt;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  text-transform: uppercase;

  &:first-child {
    max-width: 300px;
    display: table;
    margin: 0 auto;
  }

  @media (max-width: 992px) {
    font-size: 9pt;
  }

  @media (max-width: 350px) {
    font-size: 7pt;
  }

  &:hover,
  &:focus {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}