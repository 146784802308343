@import '../variables.scss';

.Divider {
  background: $basic-grey;
  height: 3px;
  margin: 2rem 0;
  position: relative;
}

.DividerText {
  background: white;
  color: #111;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 10px;
  font-weight: bold;
  letter-spacing: 1.5px;
}
