.milestones-img {
  max-height: 200px;
  max-width: 150px;
  width: 100%;

  @media (min-width: 768px) {
    width: 75%;
  }

  @media (min-width: 1024px) {
    max-height: 200px;
    max-width: 200px;
  }
}

.milestones-text {
  font-size: 10pt;

  @media (max-width: 768px) {
    font-size: 9pt;
  }

  @media (max-width: 350px) {
    font-size: 7pt;
  }
}

.milestones-carousel-image {
  height: 135px;
  width: 135px;
  margin-left: auto;
  margin-right: auto;

 

  @media (min-width: 768px) and (max-width:1023px) {
    width: 115px;
    height: 115px;
  }

  @media (min-width: 320px) and (max-width:767px) {
    width: 125px;
    height: 125px;
  }


}

.milestone-carousel {
  
  .ant-carousel {
    width: 100%;
    margin: auto;
  }
  @media (min-width:1200px) {
    width: 90%;
    margin:auto;
  }


  @media (max-width:768px) {
    width: 95%;
    margin:auto;
  }

  @media (max-width:520px) {
    width: 90%;
    margin:auto;
  }

  @media (max-width:420px) {
    width: 100%;
    margin:auto;
  }
  .dashboard-milestone-card {
    position: "relative";
    height: 195px;
    width: 168px;

  @media (max-width: 767px) {
    width: 132px;
    height: 165px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 138px;
    height: 158px;
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    width: 168px;
    height: 190px;
  }


    .completed-icon {
      margin-left: 8.5rem;
      margin-top:0.85rem;
      margin-bottom: -2rem;
  
      @media (max-width:767px) {
        margin-top:0.75rem;
        margin-left: 6.8rem;
        margin-bottom:-1.85rem;
      }

      @media (min-width: 768px) and (max-width: 1023px) {
        margin-top:0.75rem;
        margin-left: 6.8rem;
        margin-bottom:-1.85rem;
      }

    
    }
  }

  .milestone-link {
    font-family: "MontserratSemiBold";
    color:#111111;
    font-size: 16px;


    @media (min-width:320px) and (max-width:767px) {
      font-size: 12px ;
    }

    @media (min-width:768px) and (max-width:1023px) {
      font-size: 13px ;
    }
  }

}

.milestone-carousel .slick-dots li button {
  width: 7px;
  height: 7px;
  border-radius: 40px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: #323643;
  opacity: 0.3;
}

.milestone-carousel .slick-dots {
  @media(max-width:768px) {
    bottom: 5px;
  }

  @media(max-width: 320px) {
    bottom:-20px;
  }
}


.milestone-carousel .slick-dots li.slick-active button {
  width: 7px;
  height: 7px;
  border-radius: 40px;
  border: solid 1px #000;
  opacity: 0.6;
  background-color: #fff;
}

.milestone-carousel {
  .slick-dots li {
    display: none
  }

 .slick-dots li.slick-active,
 .slick-dots li.slick-active + li,
 .slick-dots li.slick-active + li + li,
 .slick-dots li.slick-active + li + li + li,
 .slick-dots li.slick-active + li + li + li + li {
   display: inline-block;
 }

 .slick-dots li:nth-last-child(1),
 .slick-dots li:nth-last-child(2),
 .slick-dots li:nth-last-child(3),
 .slick-dots li:nth-last-child(4),
 .slick-dots li:nth-last-child(5){
   display: inline-block;
 }

 .slick-dots li.slick-active ~ li:nth-last-child(1),
 .slick-dots li.slick-active ~ li:nth-last-child(2),
 .slick-dots li.slick-active ~ li:nth-last-child(3),
 .slick-dots li.slick-active ~ li:nth-last-child(4),
 .slick-dots li.slick-active ~ li:nth-last-child(5)  {
   display: none;
 }

 .slick-dots li.slick-active + li + li + li + li:nth-last-child(5),
 .slick-dots li.slick-active + li + li + li + li:nth-last-child(4),
 .slick-dots li.slick-active + li + li + li + li:nth-last-child(3),
 .slick-dots li.slick-active + li + li + li + li:nth-last-child(2),
 .slick-dots li.slick-active + li + li + li + li:nth-last-child(1),
 .slick-dots li.slick-active + li + li + li:nth-last-child(5),
 .slick-dots li.slick-active + li + li + li:nth-last-child(4),
 .slick-dots li.slick-active + li + li + li:nth-last-child(3),
 .slick-dots li.slick-active + li + li + li:nth-last-child(2),
 .slick-dots li.slick-active + li + li + li:nth-last-child(1),
 .slick-dots li.slick-active + li + li:nth-last-child(5),
 .slick-dots li.slick-active + li + li:nth-last-child(4),
 .slick-dots li.slick-active + li + li:nth-last-child(3),
 .slick-dots li.slick-active + li + li:nth-last-child(2),
 .slick-dots li.slick-active + li + li:nth-last-child(1),
 .slick-dots li.slick-active + li:nth-last-child(5),
 .slick-dots li.slick-active + li:nth-last-child(4),
 .slick-dots li.slick-active + li:nth-last-child(3),
 .slick-dots li.slick-active + li:nth-last-child(2),
 .slick-dots li.slick-active + li:nth-last-child(1){
   display: inline-block;
 }

}

