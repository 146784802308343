.ant-btn {
  font-family: 'Roboto', sans-serif;
  font-size: 14pt;
  padding: 1.5rem 15px;
  line-height: 0;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);

  &.default-btn {
    font-size: 14px;
    padding: 0 15px;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.ant-btn-lg,
.ant-btn-round.ant-btn-lg {
  font-size: 16pt;
  height: auto;
  padding: 0.75rem 0.8rem;
  transition: 0.3s ease-in;
  line-height: 1.5;

  @media (max-width: 768px) {
    padding: 0.5rem 0.8rem;
  }
}

.ant-btn-sm {
  padding: 0 7px;
  font-size: 14px;
}

.ant-btn-primary:hover {
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.ant-btn-link,
.ant-btn-link:hover,
.ant-btn-link:focus {
  box-shadow: none;
}

.ant-btn-icon-only {
  padding: 0;
  font-size: 16px;
  border-radius: 4px;
}

.ant-btn-circle,
.ant-btn-circle-outline {
  padding-right: 0;
  padding-left: 0;
  border-radius: 50%;
}

.ant-menu-item,
.ant-menu-submenu-title {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon,
.ant-menu-item,
.ant-menu-submenu-title {
  font-size: 12pt;

  @media (max-width: 768px) {
    font-size: 16pt;
  }

  @media (min-width: 1280px) {
    font-size: 14pt;
  }
}

.anticon-warning {
  color:#1c2e5e !important;
}

.ant-page-header {
  min-height: 60px;
  z-index: 997;

  @media (max-width: 768px) {
    height: 60px;
    padding: 0px;
  }
}

.ant-drawer-body {
  padding: 2em 0 0 0;
}

.ant-drawer-content,
.ant-drawer-body,
.ant-menu,
.ant-menu-item,
.ant-menu-submenu-title {
  background-color: #ffffff !important;

  &.disabled {
    opacity: .3 !important;
    cursor: default !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }
}

.ant-menu-item,
.ant-menu-submenu-title {
  border: 0;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected,
.ant-menu-submenu-selected,
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected,
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $secondary-color;

  &.disabled {
    opacity: .3 !important;
    cursor: default !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }
}

.ant-table-pagination.ant-pagination {
  margin: 16px;
}

.ant-tabs-nav {
  margin: auto;
  display: table;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-btn-icon-only {
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

.ant-input-lg {
  border-radius: 0.625rem;
  height: 3.125rem;
  padding: 0.5rem;
}

.ant-input-group-addon {
  background-color: $primary-color;
  border-color: $primary-color;
  color: white;
}

.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: #eeeeee;
}

.ant-table-thead > tr > th {
  background: $primary-color;
  color: #ffffff;
  font-size: 12pt;
  font-family: 'Roboto', sans-serif;
}

.ant-radio-wrapper {
  display: inline-flex;
  align-items: center;
}

.ant-card-hoverable:hover {
    -webkit-box-shadow: $secondary-color;
    box-shadow: 0 2px 8px $secondary-color;
}
