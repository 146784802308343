@import '../../variables.scss';


.sort-selected {
    color: $secondary-color !important;
}

.custom-margin{
  @media (min-width:1366px) and (max-height:600px ) {
    margin-top: -1rem;
  }
}

.select-babypages-margin-bottom {

    @media (min-width:320px) and (max-width:767px) {
        margin-bottom: 3rem !important;
    }

    @media (min-width:768px) and (max-width:1023px) {
        margin-bottom: 5rem !important;
    }
}

.baby-title {
    margin-bottom: 0;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
    color:$black;
    text-align: center;
}

.selected-babypages {
    color:#989696;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 13px;
}

.select-babypages-button-container {
   margin:  1rem;
    @media (min-width: 1920px) and (min-height:1080px) {
      margin: 1.75rem 0;
    }
    
    @media (min-width:1024px) and (max-height:900px) {
        margin: 1.5rem 0;
      }

    @media (min-width:1024px) and (max-height:768px) {
        margin: 1.2rem 0;
      }

      @media (min-width:1024px) and (max-height: 720px) {
        margin: 1rem 0;
      }

      @media (max-width:820px) {
        margin: 1.5rem 0;
      }

      @media (max-width:520px) {
        margin-top: 1.5rem;
      }
}