.sort-container {
    padding: 0.125rem 0.5rem;
    margin: 0 0 0 1.5rem;

    @media(min-width:820px) {
        margin: 0 0 0 35px;
    }
}

.ant-radio-inner::after {
  top:3.2px;
  left: 3.2px;
}
