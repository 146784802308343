.manage-children-title {
    display: flex;
    color: #000 !important;
    justify-content: center;
    align-items: center;
    font-family: 'MontserratBold' !important;
    font-size: 10.6px !important;

    h2 {
      color: #000 ;
    }
    .add-icon {
      padding-bottom: 6px;
      margin-top: 8.5px;
      margin-left: 2.5px;
    }
}

.profile-photo-icon {
  position:relative; 
  top:-30%;
  left:80%;
}

.profile-photo-icon-xs {
  position:relative; 
  top:-15px;
  left:75%;
}

.profile-photo-icon-header {
  position:relative; 
  top: 15px !important;
  left:-25%;
}