@import '../../variables.scss';

.BasePage {
  height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

.header {
  background-color: #ffffff;
}

.header-logo {
  top: 4.5px;
  left: 20px;
  position: absolute;
  transition: 0.1s ease-in;
  z-index: 1;

  &__img {
    height: 60px;
    width: auto;
  }

  @media (max-width: 1023px) {
    top: 4.5px;
    left: 15px;
  }
}

.header-menu {
  position: absolute;
  width: 100vw;
  height: 60px;
  top: 0;
  left: 0;
}

.menu-button {
  position: absolute;
  right: 10px;
  top: 10px;

  .ant-badge-count {
    top: 5px;
    right: 5px;
  }
}

.cart-button-badge{
  position: absolute;
  right: 70px;
  top: 20px;
  cursor: pointer;

  .cart-button {
    position: absolute;
    right: 13px;
    top: 0;
  }

  @media (min-width:320px) and (max-width:375px) {
    right: 60px;
    
    .ant-badge-count {
      min-width: 18px !important;
      height: 18px !important;
    }
    
    .cart-button {
      right: 13px;
      top: 0;
    }
  }
}

.page-title {
  color: #444444;
  opacity: 0.5;
  font-family: "MontserratSemiBold";
  letter-spacing: 2px;
  font-size:12px;
  text-transform: uppercase;
}

.page-subtitle {
  font-family: "Montserrat";
  font-size:16px;
  color:#111111;
}

.child-dropdown {
  color: rgb(17, 17, 17);
  font-weight: bold;
}

.BaseContent {
  padding-bottom: 50px;

  @media (min-width: 768px) {
    padding-bottom: 80px;
  } 
}
