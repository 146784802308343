
@import "~bootstrap/scss/_functions";
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';
@import '~bootstrap/scss/_forms.scss';
@import '~bootstrap/scss/_input-group.scss';

.sort-button-icon {
  background-color: #ffffff;
  border-left: 2px solid #e7e6e6;
}
.sort-button-icon:focus {
  border-color: #f8aba0;
}

.search-button-icon {
  background-color: #ffffff;
  border-right: none;
}

.search-bar-container {
  @media (min-width: 320px) and (max-width:767px) {
    margin-top: 1.25rem;
  }
}
.search-input {
  border-radius: 0px 0px 0px 0px !important;
  border-right: none;
  border-left: none;
  font-family: "MontserratLight";
  font-size: 14px;
  height: 42px;
  padding: 6px 8px;
  color: #838383;
}

.search-input:focus {
  box-shadow: none !important;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
}

.searchButtonIcon {
  border-color: #f8aba0;
  background-color: #fafafa;
}

.searchButton {
  border-left-color: #f8aba0;
  border-radius: 0px 5px 5px 0px !important;
}

.searchButton:focus {
  box-shadow: none !important;
  border-color: #f8aba0;
}

