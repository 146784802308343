.illustration-margin {
    @media (max-width:991px) {
        margin-bottom: 3rem;
    }

    @media (max-width:768px) {
        margin-bottom: 5rem;
    }

    @media (max-width: 425px) {
        margin-bottom: 4.5rem;
    }
}

.border-margin {
    

    @media (max-width:991px) {
        margin-bottom: 3rem;
    }

    @media (max-width:768px) {
        margin-bottom: 6rem;
    }

    @media (max-width: 425px) {
        margin-bottom: 4.5rem;
    }
}