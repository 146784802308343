.cover-content {
  position: relative;
  overflow: hidden;
  background-color: white;
  height: 280px;
  width: 280px;
  margin: 0 auto 15px;

  @media (min-width: 1024px) {
    height: 320px;
    width: 320px;
  }

  @media (min-width: 1440px) {
    height: 350px;
    width: 350px;
  }

  @media (min-width: 1980px) {
    height: 450px;
    width: 450px;
  }

  .book-cover {
    position: relative;

    img {
      width: 100%;
    }

    .book-text {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      height: 25%;
      width: 80%;
      bottom: 17%;
      background-color: white;
      

      .book-title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0 4% 0 10%;
        padding-left: 3%;
        text-transform: capitalize;
        line-height: 1.8;
        //font-size: 100%;
      }

      .book-subtitle {
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: 3%;
        margin: 0 4% 0 10%;
        text-transform: capitalize;
        line-height: 1.8;
        //font-size: 100%;
      }
    }
  }
}
