.photoList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-height: 250px;
  overflow-y: auto;

  .infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  img {
    cursor: pointer;
    max-height: 300px;
    max-width: 120px;
    margin: 0.2rem;
    transition: 0.3s ease-in-out;
  }

  img:hover {
    opacity: 0.8;
  }
}

.facebook-btn {
  color: #ffffff !important;
  border: 1px solid #4267b2 !important;
  background-color: #4267b2 !important;
  transition: .3s ease-in-out;
}

.facebook-btn:hover {
  color: #4267b2 !important;
  border: 1px solid #4267b2 !important;
  background-color: #ffffff !important;
}

.google-btn {
  color: #ffffff !important;
  border: 1px solid #4285f4 !important;
  background-color: #4285f4 !important;
  transition: .3s ease-in-out;
}

.google-btn:hover {
  color: #4285f4 !important;
  border: 1px solid #4285f4 !important;
  background-color: #ffffff !important;
}

.camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  color: #fff;
}