@import "../../../variables.scss";

.milestone-filter .ant-btn {
  cursor: pointer;
  font-size: 10pt;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  color: #000;
  background-color: #fff !important;
  border-radius: 13.5px;
  width: 106px;
  height: 85px;
  border: 2px solid #fff !important;

  // &:first-child {
  //   max-width: 300px;
  //   display: table;
  //   margin: 0 auto;
  // }

  @media (min-width: 481px) and (max-width: 992px) {
    font-size: 9pt;
  }

  @media (max-width: 480px) {
    font-size: 7pt;
    width: 89px;
    height: 71px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    width: 92px;
    height: 74px;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    width: 102px;
    height: 80px;
  }

  &:hover,
  &:focus {
    background-color: #dff1f7 !important;
    border: 2px solid #0b2c37 !important;
    color: #000;
  }
}

.icons-milestones-cart {
  max-width: 29px !important;
  max-height: 33px !important;

  @media (max-width: 767px) {
    max-width: 23px !important;
    max-height: 26px !important;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    max-width: 26px !important;
    max-height: 30px !important;
  }
}

.icons-milestones-shirt {
  max-width: 34px !important;
  max-height: 33px !important;

  @media (max-width: 767px) {
    max-width: 27px !important;
    max-height: 26px !important;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    max-width: 31px !important;
    max-height: 30px !important;
  }
}

.icons-milestones-foot {
  max-width: 28px !important;
  max-height: 33px !important;

  @media (max-width: 767px) {
    max-width: 22px !important;
    max-height: 26px !important;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    max-width: 25px !important;
    max-height: 30px !important;
  }
}

.icons-milestones-sock {
  max-width: 33px !important;
  max-height: 33px !important;

  @media (max-width: 767px) {
    max-width: 26px !important;
    max-height: 26px !important;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    max-width: 30px !important;
    max-height: 30px !important;
  }
}

.icons-milestones-incomplete {
  max-width: 28px !important;
  max-height: 33px !important;

  @media (max-width: 767px) {
    max-width: 22px !important;
    max-height: 26px !important;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    max-width: 25px !important;
    max-height: 30px !important;
  }
}

.icons-milestones-face {
  max-width: 38px !important;
  max-height: 33px !important;

  @media (max-width: 767px) {
    max-width: 30px !important;
    max-height: 26px !important;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    max-width: 34px !important;
    max-height: 27px !important;
  }
}

.milestones-buttons-radius {
  border-radius: 12px !important;
}

.force-width-button {
  width: 157px !important;
  height: 42px !important;

  @media (max-width: 480px) {
    width: 135px !important;
    height: 20px !important;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    width: 135px !important;
    height: 40px !important;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    width: 150px !important;
    height: 39px !important;
  }
  
}

.milestones-buttons-text-bold {
  font-family: "MontserratSemiBold";
  color:#000000;
  font-size: 13px !important;

  @media (max-width: 767px) {
    font-size: 11px !important;
  }
}
