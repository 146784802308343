.no-pages {
  flex-direction: column;
  height: calc(100vh - 140px);

  .image {
    max-width: 280px;
    max-height: 280px;
  }

  .title {
    color: black;
  }

  .subtitle {
    color: black;
  }

  @media (min-width: 769px) {
    height: calc(100vh - 158px);
  }
}

#placeholder {
  display: grid;
  height: auto;
}

.placeholder {
  width: 30%;
  margin: auto;
}

@media (max-width: 425px) {
  .placeholder {
    width: 80%
  }

  .centered {
    text-align: center !important;
  }

  .row {
    margin-bottom: 10px;
  }

}

.centered {
  text-align: center;
}

.align-center {
  height: 100%;
  align-content: center;
}

.no-books {
  width: 100%;
  text-align: center;
}

.book-page-header {
  width: 100%;
  height: 20vh;
  background-size: cover;
  background-position: center 40%;
}

.book-list {
  position: relative;
}

@media (min-width: 610px) {
  .book-page-header {
    height: 30vh;
  }
}

@media (min-width: 910px) {
  .book-page-header {
    height: 40vh;
  }
}

@media (min-width: 1210px) {
  .book-page-header {
    height: 50vh;
  }
}

@media (min-width: 1510px) {
  .book-page-header {
    height: 60vh;
  }
}

.margin-bottom-navigation {
  @media (min-width:320px) {
    margin-bottom: 61px;
  }

  @media (min-width:767px) {
    margin-bottom: 84px;
  }

  @media (min-width:1024px) {
    margin-bottom: 120px;
  }
}

.margin-bottom-navigation-small {
  @media (min-width:320px) {
    margin-bottom: 41px;
  }

  @media (min-width:767px) {
    margin-bottom: 51px;
  }

  @media (min-width:1024px) {
    margin-bottom: 60px;
  }

  @media (min-width:1024px) and (max-height:768px) {
    margin-bottom: 80px;
  }
}

.create-book-container-width {
  max-width: 85%;
  padding: 24px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;

  @media (min-width: 768px) and (max-width: 1150px) {
    max-width: 100%;
  }

  @media (max-width: 450px) {
    margin-bottom: 0 !important;
    max-width: 100%;
    min-height: 100px;
    border-radius: 0 !important;
  }

  @media (min-width: 1151px) and (max-width: 1600px) {
    max-width: 85%;
  }

  .create-book-scrollable-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .create-book-scrollable-select-container {
    height: 360px;

    @media (max-width: 450px) {
      height: 300px;
    }
  }

  .create-book-scrollable-order-container {
    height: 420px;

    @media (max-width: 450px) {
      height: 440px;
    }
  }

  .create-book-scrollable-cover-container {
    height: 430px;

    @media (max-width: 450px) {
      height: 390px;
    }
  }

  .green-button:disabled {
    opacity: 0.5;
  }

}

.book-creation-buttons {
  font-size: 16px !important;
  font-family: 'Montserrat' !important;

  @media (min-width:320px) and (max-width:364px) {
    font-size: 11px !important;
  }

  @media (min-width:365px) and (max-width:480px) {
    font-size: 13px !important;
  }

  @media (max-width:768px) {
    padding: 20px !important
  }
}

.create-book-scrollable-container::-webkit-scrollbar {
  width: 0.5em;
}

.create-book-scrollable-container::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
  background-clip: padding-box;
}
