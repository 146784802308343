.col-profile {
  width: 100%;
  .profile-card {
    @media (max-width:1440px) and (min-width:1024px) {
      max-width: 465px !important;
      min-width: 450px !important;
    }

    @media (max-width:820px) and (min-width:768px) {
      max-width: 340px !important;
      min-width: 330px !important;
    }

    @media (max-width:520px) {
      width: 100%;
    }
  }


  @media (max-width: 768px) {
    padding: 0px !important;
    margin: 0px !important;
  }
}

.profile-tabs {
    .ant-tabs-bar {
        margin-bottom: 0 !important;
    }
    .ant-tabs-tab {
        margin: 10px 0 10px 0 !important;
        color:#000000 !important;
        font-family: "Montserrat";
    }

    .ant-tabs-tab-active {
      color: #658071 !important;
      font-family: "Montserrat";
  }
}

.profile-photo-title{
  font-family: "Montserrat";
  font-size:13px ;
  color:#909090;
}

.profile-card {
  border: 0;
  border-radius: 20px;

  @media(max-width: 767px){
    border-radius: 0px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);

  &:hover {
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
      0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
  }

  h2 {
    color:#000000;
    font-family: "MontserratSemiBold";
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.5px;
    text-align: center;
  }

  label {

    font-family:"MontserratLight";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #909090;
  }

  h3 {
    color:#111111;
    font-family: "Montserrat";
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
  }

  .payment-methods {
    font-weight: 300;
    font-family: "MontserratLight";
  }

  .white-button {
    border:1px solid #658071;
    color:#658071;
    font-family: "MontserratLight";
  }

  p {
    font-family: "MontserratLight";
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }

  .green-button {
    font-family: "MontserratLight" !important;
  }

  .underline {
    text-decoration: underline !important;
  }

  .clickable-profile-text{
    font-size: 13px;
    font-family: "Montserrat";
    font-weight: 300;
    line-height: 21px;
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    color: #658071;
    margin-bottom: 0;
  }

  .ant-card-head {
    color: #111;
    font-family: 'Roboto', 'Segoe UI', sans-serif;
    font-size: 14pt;
  }

  @media (max-width: 768px) {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    .ant-card-head {
      display: none;
    }

    .ant-card-body {
      padding: 20px;
    }
  }
}
.tabs-wrapper {
  .ant-tabs-nav-container{
    background-color: #ffffff;
  }
}


.card-select {
  .ant-select-selection {
    height: 46px;
    padding: 11px 14px;
    border-radius: 8px;
    background-color: #f0f0f0;
  }

}

.select-wrapper {
  .ant-select .ant-select-selection {
    padding-left: 10rem !important;
    font-size: 13px !important;
    font-family: "MontserratLight" !important;
    color:#000000 !important;
    line-height: normal !important;

    @media(min-width:1920px) {
      padding-left: 20rem !important;
    }
    
    @media (max-width:1440px) {
      padding-left: 12rem !important;
    }

    @media(max-width:1200px) {
      padding-left: 10rem !important;
    }

    @media(max-width:1024px) {
      padding-left: 10rem !important;
    }
  
    @media(max-width:820px) {
      padding-left: 6rem !important;
    }
  
    @media(max-width:520px) {
      padding-left: 10rem !important;
    }

    @media(max-width:425px) {
      padding-left: 8.5rem !important;
    }
    @media(max-width:375px) {
      padding-left: 5.7rem !important;
    }

    @media(max-width:320px) {
      padding-left: 4.5rem !important;
    }
  }

  .ant-select .ant-select-selection__rendered {
    line-height: normal !important;
  }
}

.prefix-icon-wrapper {
  position: absolute;
  z-index: 1;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width:1440px) {
    top:60px;
    left:30px;
  }

  @media (max-width:1440px) {
    top:60px;
    left:30px;
  }

  @media(max-width:820px) {
    top:60px;
    left:30px;
  }

  @media(max-width:768px) {
    top:56px;
    left:30px;
  }
}

.dynamic-color-card{
  @media (max-width:767px) {
    background-color: #f0f0f0 !important;
  }
}

.col-xxl-4{
  @media(min-width: 1440px) {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
}
