.ant-popover-inner-content {
    padding: 10px 10px 1px !important;
}

.mobile-button {
    cursor: pointer;
}

.default-bp-margin-bottom {
    @media (max-width:767px) {
        margin-bottom: 5rem !important;
    }
    
}

.label-font-margin {
    margin-bottom: 5rem;

    @media (min-width:769px) {
        margin-bottom: 4rem;
    }
}
