.my-order-detail .alexanders-order-img {
  max-height: 90%;
  width: auto;
  max-width: 90%;
  position: absolute;
  align-items: normal !important;
}

.my-order-detail .alexander-order-list-item {
  font-size: 10px;
}

.my-order-detail .alexander-order-list-item .ant-timeline-item-tail {
  border-left-color: rgb(247, 176, 166);
  border-left-width: 1px;
  left: 7px;
}

.my-order-detail .alexander-order-list-item .ant-timeline-item-head.ant-timeline-item-head-blue {
  background-color: #637f71;
}

.my-order-detail .alexander-order-list-item .ant-timeline-item-head.ant-timeline-item-head-blue,
.my-order-detail .alexander-order-list-item .ant-timeline-item-head.ant-timeline-item-head-gray {
  height: 15px;
  width: 15px;
}

.my-order-detail .alexander-order-list-item .ant-timeline-item-content {
  margin-left: 50px;
}

.my-order-detail .alexander-order-list-item .ant-timeline-item {
  height: 120px;
}

.orange-bb {
  border-bottom-color: rgb(247, 176, 166) !important;
  border-bottom: 1px solid;
}

@media (max-width: 450px) {
  .my-order-detail .alexander-order-list-item > div {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 375px) {
  .my-order-detail .alexander-order-list-item {
    font-size: 12px;
  }
}

@media (min-width: 414px) {
  .my-order-detail .alexander-order-list-item {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .my-order-detail .alexander-order-list-item {
    font-size: 10px;
  }
}

@media (min-width: 1024px) {
  .my-order-detail .alexander-order-list-item {
    font-size: 12px;
  }
}