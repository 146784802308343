@import "../../variables.scss";

.book-actions {
  list-style: none;
  padding: 0;
  margin: 0;
  color: $primary-color;
  font-size: 13pt;

  .book-action {
    cursor: pointer;

    &:hover {
      color: $secondary-color;
    }
  }

  .book-action-disabled {
    cursor:not-allowed;
    color: $grey-new ;

    &:hover {
      color: $grey-new ;
    }
  }

}

.book-preview {
  margin-bottom: 40px;
  cursor: pointer;

  img {
    border: 1px solid rgba($primary-color, 0.5);
    border-bottom: none;
  }

  .book-content {
    background-color: white;
    border: 1px solid rgba($primary-color, 0.5);
    border-top: none;
    padding: 0.5em;

    .title {
      color: black;
      font-size: 12pt;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      font-weight: bold;
    }

    .subtitle {
      text-align: center;
      font-size: 9pt;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
    }

    @media (max-width: 400px) {
      .title {
        font-size: 10pt;
      }

      .subtitle {
        font-size: 8pt;
      }
    }
  }
}

.book-corrupt-modal {
  .book-corrupt-container{
    padding: 15px;
    width: 470px;

    .pages-currupt-container {
      margin-top: 12px;
    }

    .pages-currupt {
      cursor: pointer;
      font-weight: bold;
    }
  }
 

  button.ant-btn-primary{
    display: none !important;
  }
}

.purchased-book {
  font-size: 16px;
  font-family: "Montserrat";

  @media (max-width:768px) {
      font-size: 14px;
  }
}
