.Layout4E {
  display: block;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  position: relative;
  outline: none;

  div {
    float: left;
  }
}
