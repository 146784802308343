
.milestones-list-container {
  background-color: "#FFF" !important;
  margin-bottom:"50px"
}

.milestones-container-width {

  max-width: 75%;
  padding: 20px;
  @media (min-width: 1150px){
    max-width: 50%; 
  }
  @media (max-width: 450px) {
    margin-bottom: 0 !important;
    max-width: 100%;
    border-radius: 0 !important;
  }

  @media (min-width: 768px) and (max-width: 1150px) {
    max-width: 90%;
  }

  // @media (min-width: 1151px) and (max-width: 1300px) {
  //   max-width: 60%;
  // }

  @media (min-width: 1151px) and (max-width: 1600px) {
    max-width: 60%;
  }

}

.milestones-container-text-bold {
  font-family: "MontserratBold";
  color: #000000;
  font-size: 16px !important;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 15px !important;
  }
}