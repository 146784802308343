.babypage-add {
  border: 1px dashed #b0b0b0;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  margin-bottom: 80px;  
}

.babypages-bar {
  background-color: white;
  height: 60px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-btn {
    padding: 1.15rem 0;
    font-size: 12pt;
  }

  @media (min-width: 768px) {
    height: 80px;

    .ant-btn {
      padding: 1.5rem 0;
      font-size: 16pt;
    }
  }
}

.need-more {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.special-popup {
  .ant-modal-content {
    z-index: 1000 !important;
  }

  .ant-modal-content {

    .ant-btn {
      font-family: "Montserrat";
      font-size: 12px;
      @media (max-width:560px) {
        font-size: 10px;
      }
    }

  }
}

.refresh-btn {
  z-index: 10;
}

.babypages-container-margin {
  margin-right: 1rem !important;
  margin-left: 1rem !important;

  @media (max-width: 320px) {
    margin: 0 !important;
  }
}

.sort-title {
  font-size: 14px;
  font-family: "Montserrat";
}