.babypagelist-container {
    width: 50%;

    @media (max-width:540px) {
        width: 100%;
    }

    @media (max-width:1024px) {
        width:75%;
    }
}

.light-btn{
    padding: 20px 5px !important;
    font-size: 16px !important; 
    font-family: "MontserratLight" !important;
}

.responsive-col {
    @media (min-width: 320px) and (max-width:767px) {
        padding: 0 !important;
      }
}