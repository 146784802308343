@import '../../variables.scss';


.col-dashboard {
  width: 100%;

  @media (max-width: 768px) {
    padding: 0px !important;
    margin: 0px !important;
  }
}

.dashboard-card {
  border: 0;
  border-radius: 20px;

  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);

  &:hover {
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
      0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
  }

  .ant-card-head {
    color: #111;
    font-family: 'Roboto', 'Segoe UI', sans-serif;
    font-size: 14pt;
  }

  @media (max-width: 767px) {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    .ant-card-head {
      display: none;
    }

    .ant-card-body {
      padding: 0 ;
    }
    border-radius: 0;
  }

  .ant-card-body {
    padding: 20px;
  }
}

.popup-info {
  width: 400px !important;
  max-width: 90% !important;
}

.img-wrapper {
  background: $primary-color-alpha;
  border-radius: 50%;
  padding: 1rem;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  img {
    max-height: 80;
    max-width: 80;
  }
}

.dashboard-card-border{
  border-bottom:2px solid #d8d8d8;
}