.guideline {
  h2 {
    font-size: 16pt;

    @media (max-width: 768px) {
      font-size: 13pt;
    }
  }

  p {
    font-size: 13pt;

    @media (max-width: 768px) {
      font-size: 11pt;
    }
  }
}